import React, { FC, SyntheticEvent, ReactNode, forwardRef, RefObject } from 'react';
import classNames from 'classnames';
import { Iconned } from '../icons/Iconned';

export type ButtonProps = {
	onClick?: (e: SyntheticEvent) => void;
	href?: string;
	type?: 'submit' | 'button' | 'reset';
	variant?: 'secondary' | 'play' | 'sm';
	disabled?: boolean;
	icon?: ReactNode;
	isFullwidth?: boolean;
	isWider?: boolean;
	isLoading?: boolean;
	onMobileHide?: boolean;
	onDesktopHide?: boolean;
	target?: string;
	ref?: RefObject<HTMLAnchorElement>;
	children?: ReactNode;
};

export const Button = forwardRef<HTMLAnchorElement, ButtonProps>(
	(
		{
			children,
			onClick,
			type,
			href,
			disabled,
			icon,
			isFullwidth,
			isWider,
			isLoading,
			onMobileHide,
			onDesktopHide,
			variant,
			target,
		},
		ref
	) => {
		const props = {
			onClick,
			className: classNames(
				'btn',
				variant && `btn--${variant}`,
				disabled && 'is-disabled',
				isFullwidth && 'btn--block',
				isWider && 'btn--x-s6',
				isLoading && 'is-loading',
				onMobileHide && 'hide-mobile',
				onDesktopHide && 'hide-desktop'
			),
			target,
		};

		const child = (
			<span className="btn__text">
				{icon ? (
					<Iconned position="before" size="m" icon={icon}>
						{children}
					</Iconned>
				) : (
					<>{children}</>
				)}
			</span>
		);

		if (href)
			return (
				<a href={href} {...props} ref={ref}>
					{child}
				</a>
			);

		if (!type) return <span {...props}>{child}</span>;

		return (
			<button type={type} disabled={disabled} {...props}>
				{child}
			</button>
		);
	}
);
