import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';

type Props = {
	icon: ReactNode;
	position?: 'before' | 'bottom';
	size?: 'm';
	isBlock?: boolean;
};

export const Iconned: FC<Props> = ({ icon, position, size, children, isBlock }) => {
	const classes = classNames('iconned', position && `iconned--${position}`, size && `iconned--size-${size}`);
	const As = isBlock ? 'div' : 'span';
	return (
		<As className={classes}>
			<As className="iconned__text last-mb-0">{children}</As>
			{icon}
		</As>
	);
};
